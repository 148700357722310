import React, { useState, useEffect } from 'react';
import FlightDataDispatchTableIcon, {UploadStatusIconBase} from './FlightDataDispatchTableIcon';

const TerrainMapperOfficeUploads = ({flight}) => {
    const [uploadValueDict, setUploadValueDict] = useState(undefined);
    // console.log("flight", flight);
    const getUploadValues = (flight) =>{
        let uploadDict = {};
        uploadDict["TerrainMapperSOL"] = "NA";
        uploadDict["TerrainMapperLAZ"] = "NA";

        flight.Uploads.forEach(upload => {

            let dispatch="Upload";

            uploadDict[upload.Type] = {
                FlightID : flight.FlightID,
                Dispatch : dispatch,//["TerrainMapperGPS", "TerrainMapperLIDAR"].includes(upload.Type) ? flight.Sensors[0].DataDispatchMethodLiDAR : flight.Sensors[0].DataDispatchMethodImagery,
                Sensor : upload.SensorName,
                Type : upload.Type,
                UploadID : upload._id,
                UploadStatus : upload.Status,
                UploadUser : upload.User || ''
            };
        });
        // console.log(uploadDict);
        return uploadDict;
    }

    useEffect(() => {
        const result = getUploadValues(flight);
        setUploadValueDict(result);
      }, [flight]);

  return (
    
    // <span>blah</span>
    <>
    {   flight && uploadValueDict !== undefined && (
        <ul style={{listStyleType:"none", display:"flex", padding:0, margin:0, gap:"10px", justifyContent:"center"}}>
            <li>
                <span style={{fontWeight:"bolder", fontSize:10}}>SOL</span>
                <UploadStatusIconBase
                    type={uploadValueDict["TerrainMapperSOL"].Type}
                    status={uploadValueDict["TerrainMapperSOL"].Dispatch}
                    uploadUser={uploadValueDict["TerrainMapperSOL"].UploadUser}
                    uploadState={uploadValueDict["TerrainMapperSOL"].UploadStatus ? uploadValueDict["TerrainMapperSOL"].UploadStatus : ""}
                    flightID={uploadValueDict["TerrainMapperSOL"].FlightID} 
                    uploadID={uploadValueDict["TerrainMapperSOL"].UploadID}
                    archiveS3Path={""}
                    documentMajorVersion = {""}
                    explorerOnClick={()=> {}}
                />
            </li>
            <li>
                <span style={{fontWeight:"bolder", fontSize:10}}>LAZ</span>
                <UploadStatusIconBase
                    type={uploadValueDict["TerrainMapperLAZ"].Type}
                    status={uploadValueDict["TerrainMapperLAZ"].Dispatch}
                    uploadUser={uploadValueDict["TerrainMapperLAZ"].UploadUser}
                    uploadState={uploadValueDict["TerrainMapperLAZ"].UploadStatus ? uploadValueDict["TerrainMapperLAZ"].UploadStatus : ""}
                    flightID={uploadValueDict["TerrainMapperLAZ"].FlightID} 
                    uploadID={uploadValueDict["TerrainMapperLAZ"].UploadID}
                    archiveS3Path={""}
                    documentMajorVersion = {""}
                    explorerOnClick={()=> {}}
                />
            </li>
        </ul>
            )
    }
    </>
  );
};

export default TerrainMapperOfficeUploads;