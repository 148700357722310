import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import axios, { CancelToken } from 'axios'
import { 
    FileMetadata, UploadTask, PresignedURLRequest, 
    PresignedURLResponse, GetUploadStatusResponse,
    UpdateUploadStatusResponse, UpdateUploadStatusRequest,
    CheckUploadProgressResponse, CheckUploadProgressErrorResponse,
    UpdateUploadHeartbeatResponse, UploaderStore, ProjectMetadata,
    FetchFlightByFlightIdResponse, FetchSensorEquipmentListResponse,
    UploadMeta, UpdateGlobalUploadProgressRequest, 
    FetchUploadProgressResponse, FetchUploadRecordResponse,
    CheckConcurrentUploadResponse
} from './UploaderTypes'
import Config from '../app/Config.json'

//////////////////////////////////
//           Constants          //
//////////////////////////////////
export const UPLOADER_ACTION_TYPE = ({
    // ADD_UPLOAD_FILELIST: 'ADD_UPLOAD_FILELIST',
    ADD_UPLOAD_METADATA_LIST: 'ADD_UPLOAD_METADATA_LIST',
    SUBMIT_UPLOAD_METADATA_LIST: 'SUBMIT_UPLOAD_METADATA_LIST',
    DELETE_UPLOAD_METADATA_LIST: 'DELETE_UPLOAD_METADATA_LIST',
    ADD_UPLOAD_TASK_LIST: 'ADD_UPLOAD_TASK_LIST',
    UPDATE_UPLOAD_STATUS: 'UPDATE_UPLOAD_STATUS',
    COMPLETE_UPLOAD_TASK: 'COMPLETE_UPLOAD_TASK',
    FILTER_REMAINING_UPLOAD_TASK: 'FILTER_REMAINING_UPLOAD_TASK',           // When resume
    CLEAR_UPLOADER_STORE: 'CLEAR_UPLOADER_STORE',
    // Move a task from uploadTaskList to ongoingTaskLookup. Delete it from uploadTaskList.
    ADD_UPLOAD_ONGOING_TASK: 'ADD_UPLOAD_ONGOING_TASK',
    UPDATE_UPLOAD_ONGOING_TASK_STATUS: 'UPDATE_UPLOAD_ONGOING_TASK_STATUS',
    UPDATE_UPLOAD_ONGOING_TASK_PROGRESS: 'UPDATE_UPLOAD_ONGOING_TASK_PROGRESS',
    // Move a task from ongoingTask to completedTask. Delete it from ongoingTaskLookup
    COMPLETE_UPLOAD_ONGOING_TASK: 'COMPLETE_UPLOAD_ONGOING_TASK',
    // Move all ongoing tasks back to uploadTaskList. It happens when upload is paused.
    WITHDRAW_ALL_UPLOAD_ONGOING_TASKS: 'WITHDRAW_ALL_UPLOAD_ONGOING_TASKS',
    CANCEL_UPLOAD_TASK: 'CANCEL_UPLOAD_TASK',           // Delete metadata and upload tasks
    // INIT_GLOBAL_UPLOAD_PROGRESS: 'INIT_GLOBAL_UPLOAD_PROGRESS',     // update file size and file count
    UPDATE_GLOBAL_UPLOAD_PROGRESS: 'UPDATE_GLOBAL_UPLOAD_PROGRESS',
    UPDATE_GLOBAL_UPLOAD_SPEED: 'UPDATE_GLOBAL_UPLOAD_SPEED',
    FETCH_FLIGHT_BY_FLIGHT_ID: 'FETCH_FLIGHT_BY_FLIGHT_ID',
    FETCH_FLIGHT_BY_FLIGHT_ID_SUCCEED: 'FETCH_FLIGHT_BY_FLIGHT_ID_SUCCEED',
    FETCH_FLIGHT_BY_FLIGHT_ID_FAILED: 'FETCH_FLIGHT_BY_FLIGHT_ID_FAILED',
    FETCH_SENSOR_EQUIPMENT_LIST: 'FETCH_SENSOR_EQUIPMENT_LIST',
    FETCH_SENSOR_EQUIPMENT_LIST_SUCCEED: 'FETCH_SENSOR_EQUIPMENT_LIST_SUCCEED',
    FETCH_SENSOR_EQUIPMENT_LIST_FAILED: 'FETCH_SENSOR_EQUIPMENT_LIST_FAILED',
    UPDATE_UPLOAD_META: 'UPDATE_UPLOAD_META',
    FETCH_UPLOAD_PROGRESS: 'FETCH_UPLOAD_PROGRESS',
    FETCH_UPLOAD_PROGRESS_SUCCEED: 'FETCH_UPLOAD_PROGRESS_SUCCEED',
    FETCH_UPLOAD_PROGRESS_FAILED: 'FETCH_UPLOAD_PROGRESS_FAILED',
    CHECK_UPLOAD_PROGRESS: 'CHECK_UPLOAD_PROGRESS',
    CHECK_UPLOAD_PROGRESS_SUCCEED: 'CHECK_UPLOAD_PROGRESS_SUCCEED',
    CHECK_UPLOAD_PROGRESS_FAILED: 'CHECK_UPLOAD_PROGRESS_FAILED',
    FETCH_UPLOAD_RECORD: 'FETCH_UPLOAD_RECORD',
    FETCH_UPLOAD_RECORD_SUCCEED: 'FETCH_UPLOAD_RECORD_SUCCEED',
    FETCH_UPLOAD_RECORD_FAILED: 'FETCH_UPLOAD_RECORD_FAILED', 
    UPDATE_EXISTING_DATA: 'UPDATE_EXISTING_DATA',
    ENABLE_UPLOAD_QUEUE_POLLING: 'ENABLE_UPLOAD_QUEUE_POLLING',
    DISABLE_UPLOAD_QUEUE_POLLING: 'DISABLE_UPLOAD_QUEUE_POLLING',
})

//////////////////////////////////
//        ActionCreators        //
//////////////////////////////////
export const fetchFlightByFlightId = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_FLIGHT_BY_FLIGHT_ID,
})

export const fetchFlightByFlightIdSucceed = (flight: FetchFlightByFlightIdResponse): AnyAction => ({
    type: UPLOADER_ACTION_TYPE.FETCH_FLIGHT_BY_FLIGHT_ID_SUCCEED,
    flight
})

export const fetchFlightByFlightIdFailed = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_FLIGHT_BY_FLIGHT_ID_FAILED
})

export const fetchSensorEquipmentList = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_SENSOR_EQUIPMENT_LIST,
})

export const fetchSensorEquipmentListSucceed = (sensorEquipmentList: FetchSensorEquipmentListResponse): AnyAction => ({
    type: UPLOADER_ACTION_TYPE.FETCH_SENSOR_EQUIPMENT_LIST_SUCCEED,
    sensorEquipmentList
})

export const fetchSensorEquipmentListFailed = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_SENSOR_EQUIPMENT_LIST_FAILED
})

export const updateUploadMeta = (uploadMeta: UploadMeta): AnyAction => ({
    type: UPLOADER_ACTION_TYPE.UPDATE_UPLOAD_META,
    uploadMeta
})

export const updateUploadStatus = (status: string) => ({
    type: UPLOADER_ACTION_TYPE.UPDATE_UPLOAD_STATUS,
    status
})

export const addUploadMetadataList = (fileMetadataList: Array<FileMetadata>) => ({
    type: UPLOADER_ACTION_TYPE.ADD_UPLOAD_METADATA_LIST,
    fileMetadataList
})

export const addUploadTaskList = (uploadTaskList: Array<UploadTask>) => ({
    type: UPLOADER_ACTION_TYPE.ADD_UPLOAD_TASK_LIST,
    uploadTaskList
})

export const addUploadOngoingTask = (uploadTaskIndex: number) => ({
    type: UPLOADER_ACTION_TYPE.ADD_UPLOAD_ONGOING_TASK,
    uploadTaskIndex
})

export const updateUploadOngoingTaskStatus = (ongoingTaskKey: string, status: string) => ({
    type: UPLOADER_ACTION_TYPE.UPDATE_UPLOAD_ONGOING_TASK_STATUS,
    ongoingTaskKey,
    status
})

export const fetchUploadProgress = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_UPLOAD_PROGRESS,
})

export const fetchUploadProgressSucceed = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_UPLOAD_PROGRESS_SUCCEED,
})

export const fetchUploadProgressFailed = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_UPLOAD_PROGRESS_FAILED,
})

export const checkUploadProgress = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.CHECK_UPLOAD_PROGRESS,
})

export const checkUploadProgressSucceed = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.CHECK_UPLOAD_PROGRESS_SUCCEED,
})

export const checkUploadProgressFailed = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_UPLOAD_PROGRESS,
})

export const fetchUploadRecord = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_UPLOAD_RECORD,
})

export const fetchUploadRecordSucceed = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_UPLOAD_RECORD_SUCCEED,
})

export const fetchUploadRecordFailed = (): Action<string> => ({
    type: UPLOADER_ACTION_TYPE.FETCH_UPLOAD_RECORD_FAILED,
})

export const filterRemainingUploadTask = (remainingTaskKeyList: Array<string>) => {
    return ({
        type: UPLOADER_ACTION_TYPE.FILTER_REMAINING_UPLOAD_TASK,
        remainingTaskKeyList
    })
}

// ongoingTaskKey is the fullPath
export const updateUploadOngoingTaskProgress = (ongoingTaskKey: string, percentCompleted: number) => {
    return ({
        type: UPLOADER_ACTION_TYPE.UPDATE_UPLOAD_ONGOING_TASK_PROGRESS,
        ongoingTaskKey: ongoingTaskKey,
        percentCompleted
    })
}

export const completeUploadOngoingTask = (ongoingTaskKey: string) => {
    return ({
        type: UPLOADER_ACTION_TYPE.COMPLETE_UPLOAD_ONGOING_TASK,
        ongoingTaskKey
    })
}

export const withdrawAllUploadOngingTasks = () => {
    return ({
        type: UPLOADER_ACTION_TYPE.WITHDRAW_ALL_UPLOAD_ONGOING_TASKS,
    })
}

export const cancelUploadTask = () => {
    return ({
        type: UPLOADER_ACTION_TYPE.CANCEL_UPLOAD_TASK,
    })
}

export const updateGlobalUploadProgress = (updateGlobalUploadProgressRequest: UpdateGlobalUploadProgressRequest): AnyAction => {
    return ({
        type: UPLOADER_ACTION_TYPE.UPDATE_GLOBAL_UPLOAD_PROGRESS,
        ...updateGlobalUploadProgressRequest
    })
}

export const clearUploaderStore = () => {
    return ({
        type: UPLOADER_ACTION_TYPE.CLEAR_UPLOADER_STORE
    })
}

export const updateExistingData = (isUpdateExistingData: boolean) => {
    return ({
        type: UPLOADER_ACTION_TYPE.UPDATE_EXISTING_DATA,
        isUpdateExistingData
    })
}

// export const updateGlobalUploadSpeed = (globalSpeed: number) => {
//     return ({
//         type: UPLOADER_ACTION_TYPE.UPDATE_GLOBAL_UPLOAD_SPEED,
//         globalSpeed
//     })
// }

//////////////////////////////////
//         Async Actions        //
//////////////////////////////////
const BASE_URI = Config.BASE_URI as string
const TM_URI = Config.TM_URI as string
const IP_URI = Config.IP_URI as string
const REQUEST_PRESIGNED_URL_ENDPOINT = 'upload_auth'

const fetchFlightByFlightIdPromise = (token: string, 
        flightId: string): Promise<FetchFlightByFlightIdResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: BASE_URI + 'flights/' + flightId,
                headers: { authorization: "Bearer " + token },
            })
            return resolve(response.data as FetchFlightByFlightIdResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleFetchFlightByFlightId = (
        flightId: string): ThunkAction<Promise<FetchFlightByFlightIdResponse | {}>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token } = getState()
        dispatch(fetchFlightByFlightId())
        try {
            const fetchFlightByIdResponse = await fetchFlightByFlightIdPromise(token, flightId)
            // console.log('fetchFlightByIdResponse: ', fetchFlightByIdResponse)
            dispatch(fetchFlightByFlightIdSucceed(fetchFlightByIdResponse))
            return fetchFlightByIdResponse
        } catch(error) {
            console.log(error)
            dispatch(fetchFlightByFlightIdFailed())
            return {}
        }
    }
}

const fetchSensorEquipmentListPromise = (token: string): Promise<FetchSensorEquipmentListResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: IP_URI + 'sensor-equipment?page=1&pageSize=-1',
                headers: { authorization: "Bearer " + token },
            })
            return resolve(response.data as FetchSensorEquipmentListResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleFetchSensorEquipmentList = (
        ): ThunkAction<Promise<FetchSensorEquipmentListResponse | []>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token } = getState()
        dispatch(fetchSensorEquipmentList())
        try {
            const fetchSensorEquipmentListResponse = await fetchSensorEquipmentListPromise(token)
            // console.log('fetchSensorEquipmentListResponse: ', fetchSensorEquipmentListResponse)
            dispatch(fetchSensorEquipmentListSucceed(fetchSensorEquipmentListResponse))
            return fetchSensorEquipmentListResponse
        } catch(error) {
            console.log(error)
            dispatch(fetchSensorEquipmentListFailed())
            return []
        }
    }
}

const updateUploadStatusToDBPromise = (
        token: string, flightId: string, uploadId: string,
        currentUploadStatus: string, isUpdate: boolean=false):Promise<UpdateUploadStatusResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'put',
                url: BASE_URI + 'flights/' + flightId + '/upload/' + uploadId + '/status',
                headers: { authorization: "Bearer " + token },
                params: { 
                    status: currentUploadStatus,
                    isUpdate: isUpdate ? isUpdate : undefined
                }
            })
            // console.log('updateUploadStatusToDBPromise: ', response.data)
            return resolve(response.data as UpdateUploadStatusResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleUpdateUploadStatusToDB = (
        currentUploadStatus: string): ThunkAction<Promise<any>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token, uploader } = getState()
        const { uploadMeta, isUpdateExistingData } = uploader
        const { flightId, uploadId } = uploadMeta
        try {
            const updateUploadStatusResponse = await updateUploadStatusToDBPromise(
                                                            token, flightId, uploadId, currentUploadStatus,
                                                            isUpdateExistingData)
            return updateUploadStatusResponse
        } catch(error) {
            console.log(error)
        }
    }
} 

const requestPresignedURLPromise = (token: string, uploadId: string, filePath: string, uploadType: string): Promise<PresignedURLResponse> => {
    return new Promise(async (resolve, reject) => {
        const presignedURLRequest: PresignedURLRequest = {
            uploadId,
            filePath,
            uploadType
        }
        try {
            const baseUri = (['TerrainMapperLAZ', 'TerrainMapperGPS','TerrainMapperSOL', 'TerrainMapperRAW', 'TerrainMapperLIDAR', 'TerrainMapperImagery'].includes(uploadType)) ? TM_URI : BASE_URI
            const response = await axios({
                method: 'post',
                url: baseUri + REQUEST_PRESIGNED_URL_ENDPOINT,
                headers: { authorization: "Bearer " + token },
                data: presignedURLRequest,
            });
            return resolve(response.data as PresignedURLResponse)
        } catch(error) {
            // console.log(error)
            return reject(error)
        }
    })
}

const uploadBlobPromise = (
        cancelToken: CancelToken,
        presignedURLResponse: PresignedURLResponse,
        blobContent: Blob,
        onUploadProgressCallBack: ((ongoingTaskKey: string, percentCompleted: number, speed: number) => any) | null = null): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        // cast the payload
        var formData = new FormData()
        Object.entries(presignedURLResponse.fields).forEach(([k, v]) => formData.append(k, v))
        formData.append('file', blobContent)
        let fileKey = presignedURLResponse.fields.key           // for now it is {guid}/part_zips/fileName.ext
        // Extract the original fullPath from fields.key straightforward. So that don't need to pass it in.
        let fullPath = fileKey.split('/').slice(1).join('/')
        let prevPercentCompleted = 0
        let uploadStartTime = Date.now()
        try {
            const response = await axios({
                method: 'post',
                url: presignedURLResponse.url,
                headers: { 'Content-Type': 'multipart/form-data' },
                cancelToken,
                data: formData,
                onUploadProgress: (progressEvent) => {
                    let currentPercentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                    if(prevPercentCompleted !== currentPercentCompleted) {
                        // It is acutally the average speed since the upload have been started
                        // let deltaPercent = currentPercentCompleted - prevPercentCompleted
                        // let deltaCompletedSize = deltaPercent * progressEvent.total
                        // let currentTime = Date.now()
                        let deltaTime = Date.now() - uploadStartTime
                        let speed = (currentPercentCompleted * progressEvent.total / 100) / (deltaTime / 1000)
                        console.log(`Upload progress: fullPath: ${fullPath}, percent: ${currentPercentCompleted}%, speed: ${speed}`)
                        if(onUploadProgressCallBack) {
                            onUploadProgressCallBack(fullPath, currentPercentCompleted, speed)
                        }
                        prevPercentCompleted = currentPercentCompleted
                    }
                }
            })
            // formData.delete('file')         // Explicitly delete. JS engine already know
            return resolve(response)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleUploadMetadata = (
        filePath: string,
        uploadCancelToken: CancelToken,
        retries: number=0): ThunkAction<Promise<any>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token, uploader } = getState()
        const { fileMetadataList, uploadMeta, isUpdateExistingData } = uploader
        const { uploadId, uploadType, isUpdate } = uploadMeta
        let stringifiedFileMetadataList = JSON.stringify(fileMetadataList);
        let blobContent = new Blob([stringifiedFileMetadataList], { type: "application/json" });

        // const targetFilePath = filePath
        const targetFilePath = (isUpdateExistingData ? 'update/' : '') + filePath
        if(retries) {
            console.log(`Upload metadata retry: ${filePath}, retries: ${retries}`)
        }
        try {
            const presignedURLResponse = await requestPresignedURLPromise(token, uploadId, targetFilePath, uploadType)
            const uploadMetadataResonse = await uploadBlobPromise(
                                                    uploadCancelToken, 
                                                    presignedURLResponse, 
                                                    blobContent
                                                )
            return uploadMetadataResonse
        } catch(error) {
            console.log(error)
            if(retries < 2) {
                return dispatch(handleUploadMetadata(filePath, uploadCancelToken, retries + 1))
            } else {
                throw Error(`Failed to upload metadata`)
            }
        }
    }
}

// filtering 0, unless it appears for 10 samples, otherwise just display last +ive integer speed
// could add in an average if this is to variable
const speedRecords: Array<number> = [];
const getSanitisedSpeed = (currentSpeed: number): number => {
    if(speedRecords.length >= 10) {
        speedRecords.shift();
    }
    speedRecords.push(currentSpeed)

    if(speedRecords.every(item => item === 0)) {
        return currentSpeed;
    } else {
        for (let i = speedRecords.length - 1; i >= 0; i--) {
            if (speedRecords[i] > 0)
                return speedRecords[i];
        }
    }
    return 0;
}

// Have to warp up the dispatch method in it. Because uploadBlobPromise is a separate function.
const updateUploadOngoingTaskProgressCallBack =  (dispatch: ThunkDispatch<any, unknown, Action<string>>) => {
    return (ongoingTaskKey: string, percentCompleted: number, speed?: number) => {
        if(speed || speed === 0) {
            getSanitisedSpeed(speed)
            dispatch(updateGlobalUploadProgress({globalSpeed: getSanitisedSpeed(speed)}))
        }
        return dispatch(updateUploadOngoingTaskProgress(ongoingTaskKey, percentCompleted))
    }
}

export const handleUploadBlob = (
        filePath: string,
        blobContent: Blob,
        uploadCancelToken: CancelToken,
        retries: number=0): ThunkAction<Promise<any>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token, uploader } = getState()
        const { uploadMeta, isUpdateExistingData } = uploader
        const { uploadId, uploadType, isUpdate } = uploadMeta

        if(retries) {
            console.log(`Upload task retry: ${filePath}, retries: ${retries}`)
        }
        try {
            const targetFilePath = filePath
            // const targetFilePath = (isUpdateExistingData ? 'update/' : '') + filePath
            const presignedURLResponse = await requestPresignedURLPromise(token, uploadId, targetFilePath, uploadType)
            const uploadBlobResonse = await uploadBlobPromise(
                uploadCancelToken, 
                presignedURLResponse, 
                blobContent,
                updateUploadOngoingTaskProgressCallBack(dispatch)
            )
            return uploadBlobResonse
        } catch(error) {
            if(uploadCancelToken.reason) {
                console.log(`Upload Cancelled: ${uploadCancelToken.reason}`)
                return { isCancelled: true }
            } else {
                console.log(`${error}: Upload Blob ${filePath} at retires ${retries}`)
                // Status code: 503; Error code: SlowDown
                if(!(error as any).response || ((error as any).response && (error as any).response.status !== 503)) {
                    if(retries < 2) {
                        // console.log(`Retry upload blob ${filePath}, Retries: ${retries + 1}`)
                        dispatch(handleUploadBlob(filePath, blobContent, uploadCancelToken, retries + 1))
                    }
                }   
            }
        }
    }
}

const fetchUploadProgressPromise = (
        token: string, flightId: string, uploadId: string, uploadType: string, isUpdate: boolean=false
        ): Promise<FetchUploadProgressResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const baseUri = (['TerrainMapperLAZ', 'TerrainMapperGPS', 'TerrainMapperSOL', 'TerrainMapperRAW', 'TerrainMapperLIDAR', 'TerrainMapperImagery'].includes(uploadType)) ? TM_URI : BASE_URI
            const response = await axios({
                method: 'get',
                url: baseUri + 'flights/' + flightId + '/upload_progress/' + uploadId,
                headers: { authorization: "Bearer " + token },
                params: { isUpdate: isUpdate ? isUpdate : undefined }
            })
            return resolve(response.data as FetchUploadProgressResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleFetchUploadProgress = (
        ): ThunkAction<Promise<FetchUploadProgressResponse | {}>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token, uploader } = getState()
        const { uploadMeta, isUpdateExistingData } = uploader
        const { flightId, uploadId, uploadType } = uploadMeta
        dispatch(fetchUploadProgress())
        try {
            const fetchUploadProgressResponse = await fetchUploadProgressPromise(token, flightId, uploadId, uploadType, isUpdateExistingData)
            // console.log(`fetchUploadProgressResponse: ${JSON.stringify(fetchUploadProgressResponse, null, 4)}`)
            dispatch(fetchUploadProgressSucceed())
            return fetchUploadProgressResponse
        } catch(error) {
            console.log(error)
            dispatch(fetchUploadProgressFailed())
            return {}
        }
    }
}

const checkUploadProgressPromise = (
        token: string, flightId: string, uploadId: string, fileMetadataList: Array<FileMetadata>, 
        uploadType: string, isUpdate: boolean=false): Promise<CheckUploadProgressResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const baseUri = (['TerrainMapperLAZ', 'TerrainMapperGPS','TerrainMapperSOL', 'TerrainMapperRAW', 'TerrainMapperLIDAR', 'TerrainMapperImagery'].includes(uploadType)) ? TM_URI : BASE_URI
            const response = await axios({
                method: 'post',
                url: baseUri + 'flights/' + flightId + '/upload_progress/' + uploadId,
                headers: { authorization: "Bearer " + token },
                params: { isUpdate: isUpdate ? isUpdate : undefined },
                data: { 'metadata': fileMetadataList }
            })
            return resolve((response.data as CheckUploadProgressResponse))
        } catch(error) {
            return reject(error)
        }
    })
}

// Happens when trying to resume from another session
export const handleCheckUploadProgress = (
        ): ThunkAction<Promise<CheckUploadProgressResponse | CheckUploadProgressErrorResponse>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token, uploader } = getState()
        const { fileMetadataList, uploadMeta, isUpdateExistingData } = uploader
        const { flightId, uploadId, uploadType } = uploadMeta
        dispatch(checkUploadProgress())
        try {
            const checkUploadProgressResponse = await checkUploadProgressPromise(token, flightId, uploadId, fileMetadataList, uploadType, isUpdateExistingData)
            dispatch(checkUploadProgressSucceed())
            return checkUploadProgressResponse
        } catch(error) {
            dispatch(checkUploadProgressFailed())
            console.log(error)
            if((error as any).response) {
                console.log((error as any).response.data)
                return (error as any).response.data
            }
        }
    }
}

const fetchUploadRecordPromise = (
        token: string, flightId: string, uploadId: string): Promise<FetchUploadRecordResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                // url: BASE_URI + 'flights/' + flightId + '/upload/' + uploadId,
                url: `${BASE_URI}flights/${flightId}/upload/${uploadId}/status`,
                headers: { authorization: "Bearer " + token },
            })
            return resolve(response.data as FetchUploadRecordResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleFetchUploadRecord = (
        ): ThunkAction<Promise<FetchUploadRecordResponse | {}>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token, uploader } = getState()
        const { flightId, uploadId } = uploader.uploadMeta
        dispatch(fetchUploadRecord())
        try {
            const fetchUploadRecordResponse = await fetchUploadRecordPromise(token, flightId, uploadId)
            dispatch(fetchUploadRecordSucceed())
            return fetchUploadRecordResponse
        } catch(error) {
            dispatch(fetchUploadRecordFailed())
            console.log(error)
            return {}
        }
    }
}

const updateUploadHeartbeatPromise = (
        token: string, flightId: string, uploadId: string, browserFingerprint: string
    ): Promise<UpdateUploadHeartbeatResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'put',
                url: BASE_URI + `flights/${flightId}/upload/${uploadId}/heartbeat`,
                headers: { authorization: "Bearer " + token },
                data: { browserFingerprint }
            })
            return resolve(response.data as UpdateUploadHeartbeatResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleUpdateUploadHeartbeat = (
        browserFingerprint: string): ThunkAction<Promise<UpdateUploadHeartbeatResponse | {}>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token, uploader } = getState()
        const { flightId, uploadId } = uploader.uploadMeta
        try {
            const updateUploadHeartbeatResponse = await updateUploadHeartbeatPromise(token, flightId, uploadId, browserFingerprint)
            return updateUploadHeartbeatResponse
        } catch(error) {
            console.log(error)
            return {}
        }
    }
}

const checkConcurrentUploadPromise = (
        token: string, uploadId: string, browserFingerprint: string
    ): Promise<CheckConcurrentUploadResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'get',
                url: BASE_URI + `uploads/${uploadId}/fingerprint/${browserFingerprint}`,
                headers: { authorization: "Bearer " + token },
            })
            return resolve(response.data as CheckConcurrentUploadResponse)
        } catch(error) {
            return reject(error)
        }
    })
}

export const handleCheckConcurrentUpload = (
        browserFingerprint: string): ThunkAction<Promise<CheckConcurrentUploadResponse | {}>, any, unknown, Action<string>> => {
    return async (dispatch, getState) => {
        const { token, uploader } = getState()
        const { flightId, uploadId } = uploader.uploadMeta
        try {
            const checkConcurrentUploadResponse = await checkConcurrentUploadPromise(token, uploadId, browserFingerprint)
            return checkConcurrentUploadResponse
        } catch(error) {
            console.log(error)
            return {}
        }
    }
}

// Hmm, it seems a nonsensical method to access the current Redux state. 
// However, it is really important when invoking callback (like setTimeout and setInterval) in useEffect.
// useEffect runs only once when it is mounted. As a result, it uses the Redux state got when it is mounted, which is stale.
// React useState has the callback solution to deal with this case. But haven't came up with a solution of Redux.
export const handleAccessCurrentReduxAuthState = (): ThunkAction<string, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { token } = getState()
        return (token as string)
    }
}

export const handleAccessCurrentReduxUploaderState = (): ThunkAction<UploaderStore, any, unknown, Action<string>> => {
    return (dispatch, getState) => {
        const { uploader } = getState()
        return uploader
    }
}

// TODO: Should update these configurations
// const BASE_URL = process.env.REACT_APP_BASE_URL as string
// const REQUEST_PRESIGNED_URL_ENDPOINT = process.env.REACT_APP_REQUEST_PRESIGNED_URL_ENDPOINT as string
// const UPLOAD_STATUS_ENDPOINT = process.env.REACT_APP_UPLOAD_STATUS_ENDPOINT as string
// const PROCESSING_ENDPOINT = process.env.REACT_APP_PROCESSING_ENDPOINT as string

// const BASE_URL = Config.BASE_URI as string
// const REQUEST_PRESIGNED_URL_ENDPOINT = 'upload_auth'
// // awsUrl: '.s3.amazonaws.com/',
// // const TARGET_BUCKET = uploadType === "LiDAR" ? Config.UPLOAD_LIDAR_BUCKET : Config.UPLOAD_IMAGERY_BUCKET

// const createNewUploadEntryPromise = (
//         token: string, sensorType: string, metadata: ProjectMetadata): Promise<string> => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             const response = await axios({
//                 method: 'post',
//                 url: BASE_URL + PROCESSING_ENDPOINT,
//                 headers: { Authorization: "Bearer " + token },
//                 data: { uploadType: sensorType, metadata }
//             })
//             return resolve(response.data.ProcessingId as string)
//         } catch(error) {
//             reject(error)
//         }
//     })
// }

// Hmm, it seems a nonsensical method to access the current Redux state. 
// However, it is really important when invoking callback (like setTimeout and setInterval) in useEffect.
// useEffect runs only once when it is mounted. As a result, it uses the Redux state got when it is mounted, which is staled.
// React useState has the callback solution to deal with this case. But haven't came up with a solution of Redux.
// export const handleAccessCurrentReduxUploaderStore = (): ThunkAction<UploaderStore, any, unknown, Action<string>> => {
//     return (dispatch, getState) => {
//         const { uploader } = getState()
//         return uploader
//     }
// }

// export const handleCreateNewUploadEntry = (
//         sensorType: string, metadata: ProjectMetadata): ThunkAction<Promise<any>, any, unknown, Action<string>> => {
//     return async (dispatch, getState) => {
//         const { auth } = getState()
//         const token = auth.token
//         try {
//             const createNewUploadEntryResponse = await createNewUploadEntryPromise(token, sensorType, metadata)
//             return createNewUploadEntryResponse
//         } catch(error) {
//             console.log(error)
//             if((error as any).response) {
//                 console.log((error as any).response.data)
//                 return (error as any).response.data
//             }
//         }

//     }
// }