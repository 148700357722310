import { FileMetadata, UploadTask } from './UploaderTypes'
import { UploadTypes } from './UploadTypeEnum';

export interface GenerateMetadataReturn {
    totalFileSize: number;
    fileMetadataList: Array<FileMetadata>;
}

export interface GenerateUploadTaskReturn {
    uploadTaskList: Array<UploadTask>;
}

class MetadataGenerationHelper {
    readonly CONFIG: { [x: string]: any }            // Configuration
    fileList: Array<File>;
    uploadTypes: Array<string>;
    flightId: string;
    isUpdateExistingData: boolean;
    fileMetadataList: Array<FileMetadata>;
    uploadTaskList: Array<UploadTask>;
    
    constructor(
            fileList: Array<File>, 
            uploadTypes: Array<string>, 
            flightId: string, 
            isUpdateExistingData: boolean=false) {
        this.CONFIG = {
            EXTN_NEVER_ZIP: ['csv', 'jgw', 'jpg', 'iiq', 'kmz', 'rawimage', 'pdf', 'laz', 'pack', 'raw', 'tif', 'sha1'],
            MAX_CHUNK_SIZE: 200 * 1024 * 1024,      // 200MB
        }
        this.fileList = fileList
        this.uploadTypes = uploadTypes
        this.flightId = flightId
        this.isUpdateExistingData = isUpdateExistingData
        this.fileMetadataList = []
        this.uploadTaskList = []
    }

    getExtn(filename: string) {
        if(filename.split('.').length === 1) {
            // throw Error('Failed to parse file extension')
            console.log(`Failed to parse file extension: ${filename}`)
            return ''
        } else {
            return (filename.split('.').pop() as string).toLowerCase()
        }
    }

    removeRootPath(originalPath: string): string {
        return originalPath.split('/').slice(1).join('/')
    }

    // The returned metadata will be POST to S3 and it is used to rebuild files
    // This is the fundamental metadata. Other metadata-liked object would be casted based off this one. 
    generateMetadata(): GenerateMetadataReturn {
        let totalFileSize = 0
        Object.keys(this.fileList).forEach((key, index) => {
            const currentFile = this.fileList[Number(key)]
            const fileName = currentFile.name
            const extn = this.getExtn(fileName)
            const isZipped = this.CONFIG.EXTN_NEVER_ZIP.indexOf(extn) !== -1 ? false : true
            // Implicitly skip 0KB files
            let exactParts = (currentFile.size / this.CONFIG.MAX_CHUNK_SIZE);
            // Explicitly upload 0KB files
            // let exactParts = (currentFile.size / this.CONFIG.MAX_CHUNK_SIZE) || 1;

            // let parts = !isZipped ? 1 : Math.ceil(exactParts)    //if not being zipped, don't chunk!
            let parts = Math.ceil(exactParts)
            let destination = null
            // Haven't found any record with uploadTypes to be [LiDAR, IMAGERY]
            if ((this.uploadTypes.indexOf(UploadTypes.LIDAR) > -1) && (this.uploadTypes.indexOf(UploadTypes.IMAGERY) > -1)) {
                destination = UploadTypes.LIDAR_IMAGERY
            } else if (['LargeFormatImagery', 'MediumFormatImagery'].includes(this.uploadTypes[0])) {
                destination = UploadTypes.IMAGERY
            } else {
                destination = this.uploadTypes[0]
            }
            this.fileMetadataList.push({
                fileName,
                parts: parts,
                zipped: isZipped,
                originalSize: currentFile.size,
                fullPath: (currentFile as any).webkitRelativePath,      // It is not decent. But default File type doesn't have webkitRelativePath.
                destination,                                            // it seems that it is not in use
            })
            totalFileSize += currentFile.size
        })
        return ({
            totalFileSize,
            fileMetadataList: this.fileMetadataList,
        })
    }

    // The returned upload tasks will be sent to Redux. It is used to manipulate and present upload progress.
    // Need to run generateMetadata() prior.
    generateUploadTask(): GenerateUploadTaskReturn {
        if(this.fileMetadataList.length == 0) {
            return ({
                uploadTaskList: []
            })
        } else {
            // Implicitly skip files with 'parts = 0'
            let basePath: string = this.isUpdateExistingData ? 'update/' : ''
            this.fileMetadataList.forEach((fileMetadata, fileMetadataIndex) => {
                for(let i=0; i < fileMetadata.parts; i++) {
                    let uploadTask: UploadTask = {
                        fileName: fileMetadata.fileName,
                        fullPath: fileMetadata.parts > 1 
                                    // ? 'part_zips/' + fileMetadata.fullPath.replace(/\//g, '@') + "@part_" + (i + 1) + '.zip'        // larger than chunk limit
                                    ? `${basePath}part_zips/` 
                                        + fileMetadata.fullPath.replace(/\//g, '@') + "@part_" + (i + 1) + '.bin'        // larger than chunk limit
                                    : fileMetadata.zipped
                                        // ? 'part_zips/' + fileMetadata.fullPath.replace(/\//g, '@') + '.zip'     // zipped and less than chunk limit
                                        ? `${basePath}part_zips/` 
                                            + fileMetadata.fullPath.replace(/\//g, '@') + '.bin'     // zipped and less than chunk limit
                                        : basePath + fileMetadata.fullPath,             // no zip and less than chunk limit 
                        targetFullPath: fileMetadata.fullPath,             // no zip and less than chunk limit 
                        isZipped: fileMetadata.zipped,
                        totalOriginalSize: fileMetadata.originalSize,
                        totalParts: fileMetadata.parts,
                        fileMetadataIndex: fileMetadataIndex,           // start from 0
                        chunkIndex: i,       // start from 0 to fileMetadata.parts - 1
                        chunkSize: (i < fileMetadata.parts - 1) 
                                    ? this.CONFIG.MAX_CHUNK_SIZE
                                    : fileMetadata.originalSize % this.CONFIG.MAX_CHUNK_SIZE,
                    }
                    this.uploadTaskList.push(uploadTask)
                }
            })
            return ({
                uploadTaskList: this.uploadTaskList
            })
        }
    }
}

export default MetadataGenerationHelper