import React, { useState, useEffect } from 'react';
import FlightDataDispatchTableIcon, {UploadStatusIconBase} from './FlightDataDispatchTableIcon';
import NicerTooltip from '../common/NicerTooltip';
import Block from '@material-ui/icons/Block';

const TerrainMapperFieldUploads = ({flight, explorerOnClick, getArchiveS3Path}) => {
    const [uploadValueDict, setUploadValueDict] = useState(undefined);
    const [allDisabled, setAllDisabled] = useState(false);
    const [otherDisabled, setOtherDisabled] = useState(false);
    
    const getUploadValues = (flight) =>{
        let uploadDict = {};
        uploadDict["TerrainMapperGPS"] = "NA";
        uploadDict["TerrainMapperLIDAR"] = "NA";
        uploadDict["TerrainMapperImagery"] = "NA";
        uploadDict["TerrainMapperRAW"] = "NA";

        flight.Uploads.forEach(upload => {

            // upload["FlightID"] = flight["FlightID"];
            // upload["Dispatch"] = ["TerrainMapperGPS", "TerrainMapperLiDAR"].includes(upload.Type) ? flight.Sensors[0].DataDispatchMethodLiDAR : flight.Sensors[0].DataDispatchMethodImagery;
            
            // // upload["Sensor"] = JSON.parse(JSON.stringify(upload["SensorName"]));
            // // delete upload["SensorName"];
            // upload["UploadID"] = upload["_id"];
            // delete upload["_id"];
            //just mapping values so I can reuse the old component

            //making assumptions about dispatch depending on sensor types
            let dispatch="Not Applicable";
            if(flight.Sensors[0].Type == "MediumFormatImagery")
                dispatch=flight.Sensors[0].DataDispatchMethodImagery;
            else if(flight.Sensors[0].Type == "LiDARandImagery")
                dispatch=["TerrainMapperGPS", "TerrainMapperLIDAR"].includes(upload.Type) ? flight.Sensors[0].DataDispatchMethodLiDAR : flight.Sensors[0].DataDispatchMethodImagery
            else if(flight.Sensors[0].Type == "LiDAR")
                dispatch=flight.Sensors[0].DataDispatchMethodLiDAR;


            uploadDict[upload.Type] = {
                FlightID : flight.FlightID,
                Dispatch : dispatch,//["TerrainMapperGPS", "TerrainMapperLIDAR"].includes(upload.Type) ? flight.Sensors[0].DataDispatchMethodLiDAR : flight.Sensors[0].DataDispatchMethodImagery,
                Sensor : upload.SensorName,
                Type : upload.Type,
                UploadID : upload._id,
                UploadStatus : upload.Status,
                UploadUser : upload.User || ''
            };
        });
        // console.log(uploadDict);
        return uploadDict;
    }

    useEffect(() => {
        const result = getUploadValues(flight);

        //disabling ALL, based off of whats already uploaded or uploading
        for (const key in result) {
            // console.log("...key",key)
            if(result["TerrainMapperRAW"]!=="NA" && result["TerrainMapperRAW"].Dispatch !== "Not Applicable" && ["TerrainMapperGPS","TerrainMapperLIDAR","TerrainMapperImagery"].includes(key) && ["UPLOADING","UPLOAD_FAILED","UPLOAD_PAUSED","UPLOAD_COMPLETE","VALIDATING_UPLOAD","VALIDATING_UPLOAD_FAILED","DATA_READY","UPLOAD_QUEUED","UPLOAD_STALLED","ARCHIVED"].includes(result[key]["UploadStatus"])) {
                setAllDisabled(true);
                break;
            }
        }
        //disabling GPS, LIDAR, Imagery if ALL uploaded or uploading
        if (result["TerrainMapperRAW"]!=="NA" && ["UPLOADING","UPLOAD_FAILED","UPLOAD_PAUSED","UPLOAD_COMPLETE","VALIDATING_UPLOAD","VALIDATING_UPLOAD_FAILED","DATA_READY","UPLOAD_QUEUED","UPLOAD_STALLED","ARCHIVED"].includes(result["TerrainMapperRAW"].UploadStatus))
            setOtherDisabled(true);


        setUploadValueDict(result);
      }, [flight]);

  return (
    
    // <span>blah</span>
    <>
    {   flight && uploadValueDict !== undefined && (
        <ul style={{listStyleType:"none", display:"flex", padding:0, margin:0, gap:"10px", justifyContent:"center"}}>
            <li>
                <span style={{fontWeight:"bolder", fontSize:10}}>GNSS</span>
                { !otherDisabled &&
                <UploadStatusIconBase
                    type={uploadValueDict["TerrainMapperGPS"].Type}
                    status={uploadValueDict["TerrainMapperGPS"].Dispatch}
                    uploadUser={uploadValueDict["TerrainMapperGPS"].UploadUser}
                    uploadState={uploadValueDict["TerrainMapperGPS"].UploadStatus ? uploadValueDict["TerrainMapperGPS"].UploadStatus : ""}
                    flightID={uploadValueDict["TerrainMapperGPS"].FlightID} 
                    uploadID={uploadValueDict["TerrainMapperGPS"].UploadID}
                    archiveS3Path={getArchiveS3Path(flight, 'LiDAR')}
                    documentMajorVersion = {flight['DocumentMajorVersion']}
                    explorerOnClick={explorerOnClick}
                />
                }
                {otherDisabled &&
                    <NicerTooltip placement="right" content={<span>Upload option disabled because of other individually uploaded items</span>}>
                        <Block style={{ color: '#cccccc', marginTop: 0, marginBottom: -5 }} />
                    </NicerTooltip>
                }
            </li>
            <li>
                <span style={{fontWeight:"bolder", fontSize:10}}>LiDAR</span>
                { !otherDisabled &&
                <UploadStatusIconBase
                    type={uploadValueDict["TerrainMapperLIDAR"].Type}
                    status={uploadValueDict["TerrainMapperLIDAR"].Dispatch}
                    uploadUser={uploadValueDict["TerrainMapperLIDAR"].UploadUser}
                    uploadState={uploadValueDict["TerrainMapperLIDAR"].UploadStatus ? uploadValueDict["TerrainMapperLIDAR"].UploadStatus : ""}
                    flightID={uploadValueDict["TerrainMapperLIDAR"].FlightID} 
                    uploadID={uploadValueDict["TerrainMapperLIDAR"].UploadID}
                    archiveS3Path={getArchiveS3Path(flight, 'LiDAR')}
                    documentMajorVersion = {flight['DocumentMajorVersion']}
                    explorerOnClick={explorerOnClick}
                />
                }
                {otherDisabled &&
                    <NicerTooltip placement="right" content={<span>Upload option disabled because of other individually uploaded items</span>}>
                        <Block style={{ color: '#cccccc', marginTop: 0, marginBottom: -5 }} />
                    </NicerTooltip>
                }
            </li>
            <li>
                <span style={{fontWeight:"bolder", fontSize:10}}>Imagery</span>

                { !otherDisabled &&
                // Dodgy hack of wrapping in a div to intercept clicks but still display status!
                <div
                    onClickCapture={(e) => {
                        e.stopPropagation(); // Intercept the click event
                        console.log('Div clicked');
                    }}
                    >
                        <NicerTooltip placement="left" content={<span>Terrain Mapper Imagery uploads <strong>coming soon</strong></span>}>
                    <UploadStatusIconBase
                        type={uploadValueDict["TerrainMapperImagery"].Type}
                        status={uploadValueDict["TerrainMapperImagery"].Dispatch}
                        uploadUser={uploadValueDict["TerrainMapperImagery"].UploadUser}
                        uploadState={uploadValueDict["TerrainMapperImagery"].UploadStatus ? uploadValueDict["TerrainMapperImagery"].UploadStatus : ""}
                        flightID={uploadValueDict["TerrainMapperImagery"].FlightID} 
                        uploadID={uploadValueDict["TerrainMapperImagery"].UploadID}
                        archiveS3Path={getArchiveS3Path(flight, 'Imagery')}
                        documentMajorVersion = {flight['DocumentMajorVersion']}
                        explorerOnClick={explorerOnClick}
                    />
                        </NicerTooltip>
                </div>
                }
                {otherDisabled &&
                <NicerTooltip placement="right" content={<span>Upload option disabled because of other individually uploaded items</span>}>
                    <Block style={{ color: '#cccccc', marginTop: 0, marginBottom: -5 }} />
                </NicerTooltip>
                }   
                {/* <NicerTooltip placement="right" content={<span>Currently disabled, coming soon</span>}>
                    <Block style={{ color: '#cccccc', marginTop: 0, marginBottom: -5 }} />
                </NicerTooltip> */}
            </li>
            <li>
                <span style={{fontWeight:"bolder", fontSize:10}}>All</span>
                {/* { !allDisabled &&
                <UploadStatusIconBase
                    type={uploadValueDict["TerrainMapperRAW"].Type}
                    status={uploadValueDict["TerrainMapperRAW"].Dispatch}
                    uploadUser={uploadValueDict["TerrainMapperRAW"].UploadUser}
                    uploadState={uploadValueDict["TerrainMapperRAW"].UploadStatus ? uploadValueDict["TerrainMapperRAW"].UploadStatus : ""}
                    flightID={uploadValueDict["TerrainMapperRAW"].FlightID} 
                    uploadID={uploadValueDict["TerrainMapperRAW"].UploadID}
                    archiveS3Path={getArchiveS3Path(flight, 'LiDAR')}
                    documentMajorVersion = {flight['DocumentMajorVersion']}
                    explorerOnClick={explorerOnClick}
                />
                }
                {allDisabled &&
                <NicerTooltip placement="right" content={<span>Upload option disabled because of other individually uploaded items</span>}>
                    <Block style={{ color: '#cccccc', marginTop: 0, marginBottom: -5 }} />
                </NicerTooltip>
                }    */}
                <NicerTooltip placement="right" content={<span>Currently disabled, coming soon</span>}>
                    <Block style={{ color: '#cccccc', marginTop: 0, marginBottom: -5 }} />
                </NicerTooltip>
            </li>
        </ul>
            )
    }
    </>
  );
};

export default TerrainMapperFieldUploads;