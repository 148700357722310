import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { 
    Grid, Button, CircularProgress, Select, MenuItem, IconButton, 
    InputAdornment, Input, Popover, Dialog, Snackbar, SnackbarContent
} from '@material-ui/core';
// import LocalAirport from '@material-ui/icons/LocalAirport'
import { flightsFetchList, flightsQueryParams } from './FlightActions'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FlightDataDispatchTableIcon from './FlightDataDispatchTableIcon';
// import FilterTextField from '../common/FilterTextField';
// import FilterComboField from '../common/FilterComboField';
import Config from '../app/Config.json'
import FlightTypeTableIcon from './FlightTypeTableIcon';
import TableSensorTypeIcon from '../common/TableSensorTypeIcon';
import TableAircraftTypeIcon from '../common/TableAircraftTypeIcon';
// import PopoverFilter from '../common/PopoverFilter';
// import PopoverDateFilter from '../common/PopoverDateFilter';
// import PopoverTextFilter from '../common/PopoverTextFilter';
// import PopoverSelectFilter from '../common/PopoverSelectFilter';
// import PopoverAutocompleteFilter from '../common/PopoverAutocompleteFilter';
import { aircraftFetchList } from '../processing/AircraftActions';
import { sensorEquipmentFetchList } from '../processing/SensorEquipmentActions';
import CancelIcon from '@material-ui/icons/Cancel';
import Search from '@material-ui/icons/Search'
import NicerTooltip from '../common/NicerTooltip';
import { clearAllFilters, uniqueValuesFetch } from '../common/CompositeFilterActions'
import { 
    getBrowserVisibilityProp, getIsDocumentHidden 
} from '../common/pageVisibilityHelper'
import { handleValidateArchive } from '../explorer/objectExplorerActions'
import SearchBar from '../common/SearchBar'
import FilterDialog from './FilterDialog'
import TerrainMapperFieldUploads from './TerrainMapperFieldUploads';
import TerrainMapperOfficeUploads from './TerrainMapperOfficeUploads';

const FLIGHT_TYPE_SELECT_OPTIONS = [
    { value: "Test Flight - Calibration Flight.", label: " Calibration Flight" },
    { value: "Test Flight - Sensor Testing", label: "Sensor Testing" },
    { value: "Test Flight - Aircraft Testing", label: "Aircraft Testing" },
    { value: "Test Flight - Pilot Training", label: "Pilot Training" },
    { value: "Successful Capture", label: "Client Flight" },
]

const SENSOR_TYPE_SELECT_OPTIONS = [
    { value: "LiDAR", label: "LiDAR" },
    { value: "MediumFormatImagery", label: "Medium Format Imagery" },
    { value: "LiDARandImagery", label: "Simultaneous Imagery (Medium Format Imagery)" },
    { value: "LargeFormatImagery", label: "Large Format Imagery" }
]

const AIRCRAFT_TYPE_SELECT_OPTIONS = [
    { value: "FixedWing", label: "Fixed-Wing" },
    { value: "Rotary", label: "Rotary" }
]

const UPLOAD_STATUS_SELECT_OPTIONS = [
    { value: "READY_FOR_UPLOAD", label: "Ready for Upload" },
    { value: "VALIDATING_SOURCE", label: "Validating Source" },
    { value: "UPLOADING", label: "Uploading" },
    { value: "UPLOAD_FAILED", label: "Upload Failed" },
    { value: "UPLOAD_CANCELLED", label: "Upload Cancelled" },
    { value: "UPLOAD_PAUSED", label: "Upload Paused" },
    { value: "UPLOAD_QUEUED", label: "Upload Queued" },
    { value: "UPLOAD_STALLED", label: "Upload Stalled" },
    { value: "VALIDATING_UPLOAD", label: "Validating Upload" },
    { value: "VALIDATING_UPLOAD_FAILED", label: "Validating Upload Failed" },
    { value: "DATA_READY", label: "Data Ready" }
]

const DISPATCH_METHOD_SELECT_OPTIONS = [
    { value: "Not Applicable", label: "Not Applicable" },
    { value: "Courier to Office", label: "Courier to Office" },
    { value: "Upload", label: "Upload" },
    { value: "Hand Deliver", label: "Hand Deliver" },
    { value: "TBA", label: "TBA" }
]

class FlightSummary extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        pollingTimeoutId: null,
        polling: false,
        isPageVisible: !getIsDocumentHidden(),
        isValidateArchiveDialogOpen: false,
        isValidateArchiveSnackBarOpen: false,
        archiveS3Path: '',
        isFilterDialogOpen: false,
        queryFilters: {},
        filterOnFocusIndex: -1,
    }

    componentDidMount() {
        this.getFlights();
        this.getSensorsAndAircraft();
        this.getProjectManagers();
        const visibilityChange = getBrowserVisibilityProp()
        document.addEventListener(visibilityChange, this.onVisibilityChange)
    }

    componentDidUpdate(prevProps, prevState) {
        if(!this.props.flightsListLoading && prevProps.flightsListLoading && this.state.isPageVisible) {
            if (this.state.pollingTimeoutId) {
                clearTimeout(this.state.pollingTimeoutId);
                this.state.pollingTimeoutId = null
                this.state.polling = false;
            }

            this.state.pollingTimeoutId = setTimeout(() => {
                this.state.polling = true;
                this.getFlights();
            }, Config.FAST_POLLING);
        }

        if(prevProps.explorerStore.isValidatingArchive !== this.props.explorerStore.isValidatingArchive) {
            this.setState({ isValidateArchiveDialogOpen: this.props.explorerStore.isValidatingArchive })
            if(!this.props.explorerStore.isValidatingArchive) {
                this.setState({ isValidateArchiveSnackBarOpen: true })
                if(this.props.explorerStore.validateArchiveSucceed && this.props.explorerStore.isArchivedSucceed) {
                    const archiveS3PathSplited = this.state.archiveS3Path.split('/')
                    let archiveBucket = archiveS3PathSplited[0]
                    let archivePrefix = archiveS3PathSplited.slice(1).join('/') + (this.state.archiveS3Path.endsWith('/') ? '' : '/')
                    let uri = encodeURI(`/explorer?bucket=${archiveBucket}&prefix=${archivePrefix}`)
                    window.open(uri)
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.state.pollingTimeoutId) {
            clearTimeout(this.state.pollingTimeoutId);
            this.state.pollingTimeoutId = null
            this.state.polling = false;
        }
        const visibilityChange = getBrowserVisibilityProp()
        document.removeEventListener(visibilityChange, this.onVisibilityChange)
        this.setState({ 
            isValidateArchiveDialogOpen: false,
            isValidateArchiveSnackBarOpen: false,
            archiveS3Path: '' 
        })
    }


    getFlights = () => {
        this.props.fetchData(this.props.token);
    }

    getSensorsAndAircraft = () => {
        if ((this.props.sensorEquipment.length === 0 || this.props.aircraft.length === 0) && !this.props.sensorEquipmentListLoading && !this.props.aircraftListLoading)
            this.props.fetchSensorsAndAircraft(this.props.token);
    }

    getProjectManagers = () => {
        this.props.fetchUniqueValues(this.props.token, "project", "ProjectManager");
    }

    onVisibilityChange = () => {
        const isHidden = getIsDocumentHidden()
        if (this.state.pollingTimeoutId && isHidden) {
            clearTimeout(this.state.pollingTimeoutId);
            this.state.pollingTimeoutId = null
            this.state.polling = false;
        }
        this.setState({ isPageVisible: !isHidden })
        if(!isHidden && !this.props.flightsListLoading) {
            this.getFlights();
        }
    }

    handleExplorerOnClick = ({type, uploadID, archiveS3Path}) => {
        console.log("...handleExplorerOnClick",type, uploadID, archiveS3Path)

        let sourceBucket = (type === "LiDAR") ? Config.UPLOAD_LIDAR_BUCKET : Config.UPLOAD_IMAGERY_BUCKET
        let sourcePrefix = `${uploadID}/`
        
        this.setState({ archiveS3Path })
        this.props.handleValidateArchive(sourceBucket, sourcePrefix)

        // const archiveS3PathSplited = archiveS3Path.split('/')
        // let archiveBucket = archiveS3PathSplited[0]
        // let archivePrefix = archiveS3PathSplited.slice(1).join('/') + (archiveS3Path.endsWith('/') ? '' : '/')
        // let uri = encodeURI(`/explorer?bucket=${archiveBucket}&prefix=${archivePrefix}`)
        // window.open(uri)
    }

    handleValidateArchiveSnackBarOnClose = () => {
        this.setState({ isValidateArchiveSnackBarOpen: false })
    }

    handleOpenFilterDialog = (index=-1) => {
        this.setState({ isFilterDialogOpen: true })
        if(index >= 0) {
            let originalFilterIndex = ((index-2) > 0) ? (index - 2) : 0
            this.setState({ filterOnFocusIndex: originalFilterIndex })
        } else {
            this.setState({ filterOnFocusIndex: -1 })
        }
    }

    handleFilterDialogOnClose = () => {
        this.setState({ isFilterDialogOpen: false })
    }

    handleUpdateQueryFilters = (queryFilters) => {
        this.setState({ queryFilters: queryFilters})
    }

    sortAllFilterList = (queryFilters) => {
        const isSurveyDateStartDefined = !!queryFilters.surveyDateStart
        const isSurveyDateEndDefined = !!queryFilters.surveyDateEnd
        return [
            { name: 'Flight Log', filterList: queryFilters.primaryIdFilterList 
                                                ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('FL')) 
                                                : []},
            { name: 'Sensor Log', filterList: queryFilters.primaryIdFilterList 
                                                ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('SL')) 
                                                : []},
            { name: 'Project Log', filterList: queryFilters.primaryIdFilterList 
                                                ? queryFilters.primaryIdFilterList.filter(filter => filter.startsWith('PRJ'))
                                                : []},
            { name: 'Aircraft', filterList: queryFilters.aircraftFilterList },
            { name: 'Survey Date', filterList: (isSurveyDateStartDefined && isSurveyDateEndDefined) 
                                                    ? [
                                                        `Start: ${queryFilters.surveyDateStart}`, `End: ${queryFilters.surveyDateEnd}`
                                                    ] 
                                                    : []},
            { name: 'Sensor', filterList: queryFilters.sensorFilterList },
            { name: 'Project Manager', filterList: queryFilters.projectManagerFilterList },
            { name: 'Flight Type', filterList: queryFilters.flightTypeFilterList },
            { name: 'Aircraft Type', filterList: queryFilters.aircraftTypeFilterList },
            { name: 'Sensor Type', filterList: queryFilters.sensorTypeFilterList },
            { name: 'Upload Status', filterList: queryFilters.uploadStatusFilterList },
            { name: 'LiDAR Dispatch Method', filterList: queryFilters.lidarDispatchMethodFilterList },
            { name: 'Imagery Dispatch Method', filterList: queryFilters.imageryDispatchMethodFilterList },
        ]
    }

    clearAllFilters = () => {
        this.setState({ queryFilters: {}})
    }

    isAnyFilterApplied = (queryFilters) => {
        if(queryFilters && Object.keys(queryFilters).length) {
            return Object.keys(queryFilters).some(queryFilterKey => queryFilters[queryFilterKey].length)
        } else {
            return false
        }
    }

    render() {

        return (
            <>
                {
                    this.isAnyFilterApplied(this.state.queryFilters)
                        && <SearchBar 
                            width={1185}
                            filtersBtnOnClick={this.handleOpenFilterDialog}
                            sortedFilterList={this.sortAllFilterList(this.state.queryFilters)}
                            clearAllFiltersCallBack={this.clearAllFilters}
                        />
                }
                <Grid container direction="column" alignItems="center" justify="center" className='mt-2'>
                    {
                        this.props.flightsListLoading && this.state.polling === false &&
                        <Grid container direction="column" alignItems="center" justify="center" style={{ padding: 10 }}>
                            <CircularProgress />
                            <div style={{ paddingTop: 5 }}>Loading Flights...</div>
                        </Grid>
                    }
                    <FlightTable
                        flightsListLoading={this.props.flightsListLoading && this.state.polling === false}
                        flights={this.props.flights}
                        queryFilters={this.state.queryFilters}
                        filtersBtnOnClick={() => this.handleOpenFilterDialog()}
                        // handleValidateArchiveCallback={this.props.handleValidateArchive}
                        explorerOnClickCallBack={this.handleExplorerOnClick}
                    />
                </Grid>
                <Dialog
                    open={this.state.isValidateArchiveDialogOpen}
                    PaperProps={{
                        style: {
                        backgroundColor: '#808080e6',
                        boxShadow: 'none',
                        minHeight: '150px',
                        minWidth: '300px',
                        borderRadius: 10,
                        },
                    }}
                >
                    <Grid container direction="column" alignItems="center" justify="center" className='pt-3'>
                        <CircularProgress />
                        <div className='pt-5' >Validating archived files...</div>
                    </Grid>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={this.state.isValidateArchiveSnackBarOpen}
                    autoHideDuration={4000}
                    onClose={this.handleValidateArchiveSnackBarOnClose}
                >
                    <SnackbarContent 
                        style={{ backgroundColor: "#1a3a69E5" }}      // have to use inline style or use withStyle()
                        message={
                            <span style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                                {this.props.explorerStore.validateArchiveSucceed
                                    ? this.props.explorerStore.isArchivedSucceed
                                        ? <>
                                            <InfoIcon className='pr-1' style={{ opacity: 0.7 }} /> 
                                            Archive validation succeed
                                        </>
                                        : <>
                                            <CancelIcon className='pr-1' style={{ opacity: 0.7 }} /> 
                                            Archive validation failed
                                        </>
                                    : <>
                                        <CancelIcon className='pr-1' style={{ opacity: 0.7 }} /> 
                                        Failed to validate archived files
                                    </>
                                }
                            </span>
                        }
                    />
                </Snackbar>
                <FilterDialog 
                    open={this.state.isFilterDialogOpen}
                    onClose={this.handleFilterDialogOnClose}
                    queryFilters={this.state.queryFilters}
                    updateQueryFiltersCB={this.handleUpdateQueryFilters}
                    selectFilterOptions={{ 
                        projectManagers: [...this.props.projectManagers],
                        sensors: [...this.props.sensorEquipment],
                        flightTypes: FLIGHT_TYPE_SELECT_OPTIONS,
                        sensorTypes: SENSOR_TYPE_SELECT_OPTIONS,
                        aircraftTypes: AIRCRAFT_TYPE_SELECT_OPTIONS,
                        uploadStatus: UPLOAD_STATUS_SELECT_OPTIONS,
                        dispatchMethods: DISPATCH_METHOD_SELECT_OPTIONS,
                    }}
                    filterOnFocusIndex={this.state.filterOnFocusIndex}
                />
            </>
        );
    }
}


class FlightTable extends Component {

    constructor(props) {
        super(props);
        this.timerReference = null;
    }

    state = {
        queryObject: {},
        rowCollaplseToggle: {},         // this state is for all rows, like {{flight_id}: boolean}
    };

    componentDidMount() {
        this.clearAllFilters();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.queryFilters !== this.props.queryFilters) {
            this.queryFiltersOnChange()
        }
    }

    clearAllFilters = () => {
        this.setState({ queryObject: {} }, this.props.setQueryParams({ params: {}, page: 1 }));//not maintaining filters between page loads
        this.props.clearAllFilters();
    }

    // getSensorInfo = (flight, key) => {
    //     let values = flight.Sensors.map(s => s[key])
    //         .filter(v => v != null && v != 'null')
    //     values = values.filter((item, pos) => values.indexOf(item) == pos) //remove duplicated
    //     return values.join(', ')
    // }


    getArchiveS3Path = (flight, uploadType = 'LiDAR') => {
        if (uploadType === 'LiDAR' && flight['archiveS3PathLiDAR']) {
            return flight['archiveS3PathLiDAR']
        } else if (uploadType === 'Imagery' && flight['archiveS3PathImagery']) {
            return flight['archiveS3PathImagery']
        } else {
            return null
        }
    }

    getSensorAndUploadValues = (flight, key) => {
        let values = [];

        let sensorDict = {};
        flight.Sensors.forEach(sensor => {

            if (sensor.Sensor === undefined && sensor.SecondarySensor !== undefined) {
                sensor.Sensor = sensor.SecondarySensor;
                delete sensor.SecondarySensor;
            }

            if (sensorDict[sensor.Sensor] == null)
                sensorDict[sensor.Sensor] = sensor;
            else {
                if (sensor.DataDispatchMethodLiDAR !== "Not Applicable" || sensor.DataDispatchMethodImagery !== "Not Applicable")
                    sensorDict[sensor.Sensor] = sensor;
            }
        });
        let distinctSensors = [];
        for (var dictKey in sensorDict) {
            distinctSensors.push(sensorDict[dictKey]);
        }

        // //filtering by sensor name, so not duplicated across sensor logs
        // let distinctSensors = flight.Sensors.filter((obj, pos, arr) => {
        //     return arr.map(mapObj => mapObj["Sensor"]).indexOf(obj["Sensor"]) === pos;
        // });

        distinctSensors.forEach(item => {
            // console.log("...item", item)
            let newObj = {};
            newObj["Dispatch"] = item[key];
            newObj["Sensor"] = item["Sensor"];
            newObj["FlightID"] = flight.FlightID;
            let terrainMapperUploads = [];

            if (flight.Uploads) {
                for (let i = 0; i < flight.Uploads.length; i++) {
                    if (flight.Uploads[i]["SensorName"] === item["Sensor"] && key === "DataDispatchMethodLiDAR" && flight.Uploads[i]["Type"] === "LiDAR") {
                        newObj["UploadStatus"] = flight.Uploads[i]["Status"];
                        newObj["UploadID"] = flight.Uploads[i]["_id"];
                        newObj["Type"] = 'LiDAR';
                        newObj["UploadUser"] = flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '';
                        break;
                    }
                    else if (item["Sensor"] && flight.Uploads[i]["SensorName"] === item["Sensor"] && key === "DataDispatchMethodImagery" && ['LargeFormatImagery', 'MediumFormatImagery', 'TerrainMapperImagery'].includes(flight.Uploads[i]["Type"])) {
                        newObj["UploadStatus"] = flight.Uploads[i]["Status"];
                        newObj["UploadID"] = flight.Uploads[i]["_id"];
                        newObj["Type"] = 'Imagery';
                        newObj["UploadUser"] = flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '';
                        break;
                    }
                    //TODO: dont break if TerrainMaperLAZ or GPS?
                    else if (item["Sensor"] && flight.Uploads[i]["SensorName"] === item["Sensor"] && key === "DataDispatchMethodLiDAR" && ["TerrainMapperLAZ", "TerrainMapperGPS",'TerrainMapperSOL', "TerrainMapperRAW", "TerrainMapperLIDAR"].includes(flight.Uploads[i]["Type"])) {
                        // newObj["UploadStatus"] = flight.Uploads[i]["Status"];
                        // newObj["UploadID"] = flight.Uploads[i]["_id"];
                        // newObj["Type"] = flight.Uploads[i]["Type"];
                        // newObj["UploadUser"] = flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '';

                        terrainMapperUploads.push({
                            "Dispatch":item[key],
                            "Sensor":item["Sensor"],
                            "FlightID":flight.FlightID,
                            "UploadStatus":flight.Uploads[i]["Status"],
                            "UploadID":flight.Uploads[i]["_id"],
                            "Type":flight.Uploads[i]["Type"],
                            "UploadUser":flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '',
                        });
                        // break;
                    }
                    else if (flight.Uploads[i]["SensorName"] === item["SecondarySensor"] && key === "DataDispatchMethodImagery" && ['LargeFormatImagery', 'MediumFormatImagery', 'TerrainMapperImagery'].includes(flight.Uploads[i]["Type"])) {
                        newObj["UploadStatus"] = flight.Uploads[i]["Status"];
                        newObj["UploadID"] = flight.Uploads[i]["_id"];
                        newObj["Type"] = 'Imagery';
                        newObj["UploadUser"] = flight.Uploads[i]["User"] ? flight.Uploads[i]["User"] : '';
                        break;
                    }
                    // else{
                    //     newObj["UploadStatus"] = "READY_FOR_UPLOAD";
                    //     newObj["UploadID"] = flight.Uploads[i]["_id"];
                    // }
                }
            }
            
            if (terrainMapperUploads.length > 0 && terrainMapperUploads.length <= 5){              // The maximum is 3 on terrain mapper
                for(let m=0;m<terrainMapperUploads.length;m++) {
                    values.push(terrainMapperUploads[m]);
                }
            }
            // if (terrainMapperUploads.length == 2){
            //     values.push(terrainMapperUploads[0]);
            //     values.push(terrainMapperUploads[1]);
            // }
            // else if (terrainMapperUploads.length == 1)
            //     values.push(terrainMapperUploads[0]);
            else
                values.push(newObj);

        });
        // console.log('...values',values)
        return values;
    }

    getSensorValues = (flight, key) => {
        let values = flight.Sensors.map(s => s[key])
            .filter(v => v != null && v != 'null')
        values = values.filter((item, pos) => values.indexOf(item) == pos) //remove duplicated
        return values;
    }

    getSensorProject = (flight, sensorId) => {
        let sensorMatch = flight.Sensors.find(sensor => sensor.SensorID == sensorId)
        // console.log(sensorMatch)
        if (sensorMatch) {//TODO remove once legacy schema migrated
            return sensorMatch.Project;
        }
        return "";
    }

    // getSpecificSensorValue = (flight, sensorName, key) => {
    //     for (let i = 0; i <= flight.Sensors.length; i++) {
    //         if (flight.Sensors[i].Sensor === sensorName) {
    //             return flight.Sensors[i][key];
    //         }
    //     }
    //     return "";
    // }

    // getProjectValues = (flight, key) => {
    //     let values = flight.Projects.map(s => s[key])
    //         .filter(v => v != null && v != 'null')
    //     values = values.filter((item, pos) => values.indexOf(item) == pos) //remove duplicated
    //     return values;
    // }

    // getSpecificProjectValues = (flight, projectId, key) => {
    //     for (let i = 0; i <= flight.Projects.length; i++) {
    //         if (flight.Projects[i].ProjectID === projectId) {
    //             return flight.Projects[i][key];
    //         }
    //     }
    //     return "";
    // }

    changePage = (evt, page) => {
        // console.log(page)
        page = page + 1
        this.props.setQueryParams({ page })
        this.setState({ rowCollaplseToggle: {} })
    }

    queryFiltersOnChange = () => {
        let queryParams = this.generateQueryParams()
        if (this.timerInterval) {
            clearTimeout(this.timerInterval);
        }
    
        this.timerInterval = setTimeout(() => {
            this.props.setQueryParams({ params: queryParams, page: 1 })
            this.setState({ filterLoading: true })
        }, 1000);
    }

    formatTextListQueryParam = (queryFilters, filterName, keyName) => {
        let prefix = (keyName.indexOf('|') >= 0) ? '$anyOr' : '$any'
        if(queryFilters[filterName] && queryFilters[filterName].length) {
            return { [keyName]: prefix + queryFilters[filterName].join(',') }
        } else {
            return {}
        }
    }

    formatDateQueryParam = (queryFilters, dateStartFilterName, dateEndFilterName, keyName) => {
        if(queryFilters[dateStartFilterName] && queryFilters[dateEndFilterName]) {
            return { [keyName]: `${queryFilters[dateStartFilterName]}+${queryFilters[dateEndFilterName]}` }
        } else {
            return {}
        }
    }

    formatPrimaryIdQueryParam = (queryFilters, filterName) => {
        const SENSOR_ID_KEY_NAME = 'Sensors.SensorID'
        const PROJECT_ID_KEY_NAME = 'Sensors.ProjectID'
        const FLIGHT_ID_KEY_NAME = 'FlightID'
        const primaryIdFilterList = queryFilters[filterName] || []
        let sensor_id_list = []
        let project_id_list = []
        let flight_id_list = []
        primaryIdFilterList.forEach(primaryId => {
            if(primaryId.startsWith('SL')) sensor_id_list.push(primaryId)
            if(primaryId.startsWith('PRJ')) project_id_list.push(primaryId)
            if(primaryId.startsWith('FL')) flight_id_list.push(primaryId)
        })
        let queryParam = {}
        if(sensor_id_list.length) queryParam[SENSOR_ID_KEY_NAME] = `$any${sensor_id_list.join(',')}`
        if(project_id_list.length) queryParam[PROJECT_ID_KEY_NAME] = `$any${project_id_list.join(',')}`
        if(flight_id_list.length) queryParam[FLIGHT_ID_KEY_NAME] = `$any${flight_id_list.join(',')}`
        return queryParam
    }

    generateQueryParams = () => {
        let primaryIdQueryParam = this.formatPrimaryIdQueryParam(this.props.queryFilters, 'primaryIdFilterList') 
        let aircraftQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'aircraftFilterList', 'Aircraft')
        let sensorQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'sensorFilterList', 'Sensors.Sensor|Sensors.SecondarySensor')
        let projectManagerQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'projectManagerFilterList', 'Sensors.Project.ProjectManager')
        // let processingTemplateQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'processingTemplateFilterList', 'ProcessingTemplate')
        let surveyDateQueryParam = this.formatDateQueryParam(this.props.queryFilters, 'surveyDateStart', 'surveyDateEnd', 'Date')
        let flightTypeQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'flightTypeFilterList', 'Sensors.Status')
        let aircraftTypeQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'aircraftTypeFilterList', 'AircraftType')
        let sensorTypeQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'sensorTypeFilterList', 'Sensors.Type')
        let uploadStatusQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'uploadStatusFilterList', 'Uploads.Status')
        let lidarDispatchMethodQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'lidarDispatchMethodFilterList', 'Sensors.DataDispatchMethodLiDAR')
        let imageryDispatchMethodQueryParam = this.formatTextListQueryParam(this.props.queryFilters, 'imageryDispatchMethodFilterList', 'Sensors.DataDispatchMethodImagery')
        let queryParams = { 
            ...aircraftQueryParam, ...sensorQueryParam, ...projectManagerQueryParam, 
            ...surveyDateQueryParam, ...primaryIdQueryParam, ...flightTypeQueryParam,
            ...aircraftTypeQueryParam, ...sensorTypeQueryParam, ...uploadStatusQueryParam,
            ...lidarDispatchMethodQueryParam, ...imageryDispatchMethodQueryParam
        }
        return queryParams
    }

    //bah... this has to do a bunch of addition filtering, because we can now have duplicate sensorlogs...which wasnt supposed to be the case
    // sensorNameRenderHelper(flight) {
    //     let allSensors = this.getSensorValues(flight, "Sensor");
    //     let allSecondarySensors = [];
    //     allSensors.map(value => {
    //         allSecondarySensors.push(this.getSpecificSensorValue(flight, value, "SecondarySensor"));
    //     });

    //     return allSensors.map((value, index) => {
    //         return (
    //             <span>
    //                 {allSensors.length > 1 && index > 0 && allSecondarySensors.indexOf(value) == -1 && <br />}
    //                 {allSecondarySensors.indexOf(value) == -1 && value}
    //                 {
    //                     this.getSpecificSensorValue(flight, value, "SecondarySensor") !== undefined &&
    //                     this.getSpecificSensorValue(flight, value, "SecondarySensor") !== "NA" &&

    //                     <span style={{ fontSize: 9, color: "#757575" }}>
    //                         <br />
    //                         {this.getSpecificSensorValue(flight, value, "SecondarySensor")}
    //                         <br />
    //                     </span>
    //                 }
    //             </span>
    //         );
    //     });
    // }

    // handleExplorerOnClick = ({type, uploadID, archiveS3Path}) => {
    //     let sourceBucket = (type === "LiDAR") ? Config.UPLOAD_LIDAR_BUCKET : Config.UPLOAD_IMAGERY_BUCKET
    //     let sourcePrefix = `${uploadID}/`
    //     this.props.handleValidateArchiveCallback(sourceBucket, sourcePrefix)
    // }

    handleCollapsetoggle = (flight_id) => {
        console.log(`handleCollapsetoggle: flight toggle: ${flight_id}`) 
        console.log(`handleCollapsetoggle: state: ${JSON.stringify(this.state, null, 4)}`)
        if(flight_id in this.state.rowCollaplseToggle) {
            this.setState(prevState => ({
                ...prevState,
                rowCollaplseToggle: {
                    ...prevState.rowCollaplseToggle,
                    [flight_id]: !prevState.rowCollaplseToggle[flight_id]
                }
            }))
        } else {
            this.setState(prevState => ({
                ...prevState,
                rowCollaplseToggle: {
                    ...prevState.rowCollaplseToggle,
                    [flight_id]: true
                }
            }))
        }
    }

    render() {
        let { flights, classes, queryParams } = this.props;
        flights = flights || [];
        const flightIdColumnStyle = { textAlign: "center", width: 50 };
        const uploadStatusColumnStyle = { textAlign: "center", width: 50 };
        const sensorIdColumnStyle = { textAlign: "center", width: 50 };
        const dateColumnStyle = { textAlign: "center", width: 50 };
        const sensorNameColumnStyle = { textAlign: "center", width: 150 };
        const aircraftColumnStyle = { textAlign: "center", width: 50 };
        // const dispatchColumnStyle = { textAlign: "center", width: 50 };
        const fieldUploadColumnStyle = { textAlign: "center", width: 150 };
        const officeUploadColumnStyle = { textAlign: "center", width: 50 };
        const headerDivTextStyle = { fontWeight: "bolder", color: "#000000" };

        return (
            <>
                {
                    !this.props.flightsListLoading && 
                        <Grid container direction='row' justify='center' alignItems='flex-start' xs={12} >
                            {/* <div style={{ width: 1124, display: this.props.flightsListLoading ? 'none' : 'inline' }}> */}
                            <div style={{ width: 1100, display: this.props.flightsListLoading ? 'none' : 'inline' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="none" style={flightIdColumnStyle}><div style={headerDivTextStyle}>Flight ID</div></TableCell>
                                            <TableCell padding="none" style={uploadStatusColumnStyle}><div style={headerDivTextStyle}>Flight Type</div></TableCell>
                                            <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Sensor ID(s)</div></TableCell>
                                            <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Project ID(s)</div></TableCell>
                                            <TableCell padding="none" style={dateColumnStyle}><div style={headerDivTextStyle}>Survey Date<br />(UTC)</div></TableCell>
                                            {/* <TableCell padding="none" style={dateColumnStyle}><div style={headerDivTextStyle}>Wheels Up<br />Wheels Down<br />(UTC)</div></TableCell>
                                            <TableCell padding="none" style={sensorIdColumnStyle}><div style={headerDivTextStyle}>Project<br />Manager</div></TableCell> */}
                                            <TableCell padding="none" style={sensorNameColumnStyle}><div style={headerDivTextStyle}>Sensor(s)</div></TableCell>
                                            <TableCell padding="none" style={uploadStatusColumnStyle}><div style={headerDivTextStyle}>Sensor Type(s)</div></TableCell>
                                            <TableCell padding="none" style={aircraftColumnStyle}><div style={headerDivTextStyle}>Aircraft</div></TableCell>
                                            <TableCell padding="none" style={uploadStatusColumnStyle}><div style={headerDivTextStyle}>Aircraft<br />Type</div></TableCell>
                                            {/* <TableCell padding="none" style={dispatchColumnStyle}><div style={headerDivTextStyle}>LiDAR<br />Status &amp;<br />Dispatch</div></TableCell>
                                            <TableCell padding="none" style={dispatchColumnStyle}><div style={headerDivTextStyle}>Imagery<br />Status &amp;<br />Dispatch</div></TableCell> */}
                                            <TableCell padding="none" style={fieldUploadColumnStyle}><div style={headerDivTextStyle}>Field Uploads</div></TableCell>
                                            <TableCell padding="none" style={officeUploadColumnStyle}><div style={headerDivTextStyle}>Office Uploads</div></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {flights.map(flight => {

                                            //urgh, quick hack to remove duplciates.
                                            let dict = {};
                                            flight.Sensors.forEach( sensor => {
                                                dict[sensor.Sensor] = sensor;
                                            })
                                            let sensors = [];
                                            for (const [key, value] of Object.entries(dict)) {
                                                sensors.push(value)
                                            }
                                            let isCollapseOpen = this.state.rowCollaplseToggle[flight.FlightID] ? this.state.rowCollaplseToggle[flight.FlightID] : false
                                            let lidarUploadList = this.getSensorAndUploadValues(flight, "DataDispatchMethodLiDAR")       // there may be more than one upload type for a single flight
                                            let isEnableCollapse = lidarUploadList.length > 2
                                            let sensorValues = this.getSensorValues(flight, "SensorID")
                                            
                                            return (
                                                <TableRow >
                                                    <TableCell padding="none" style={flightIdColumnStyle}><Link key={`Link-${flight.FlightID}`} to={"/post/" + flight.FlightID}>{flight.FlightID}</Link></TableCell>
                                                    <TableCell padding="none" style={uploadStatusColumnStyle}><FlightTypeTableIcon type={this.getSensorValues(flight, "Status")} /></TableCell>
                                                    <TableCell padding="none" style={sensorIdColumnStyle}>
                                                        {
                                                            React.Children.toArray(
                                                                sensorValues.slice(0, 2).map((value, index) => { 
                                                                    return (
                                                                        <span key={index}>{value}<br /></span>
                                                                    ) 
                                                                })
                                                            )
                                                        }
                                                        {
                                                            (sensorValues.length > 2)
                                                                && <NicerTooltip placement="right"
                                                                    content={
                                                                        React.Children.toArray(
                                                                            sensorValues.map((value, index) => { 
                                                                                return (
                                                                                    <span key={index}><strong>{value}</strong><br /></span>
                                                                                ) 
                                                                            })
                                                                        )
                                                                    }>
                                                                    <span><strong>...</strong><br /></span>
                                                                </NicerTooltip>
                                                        }
                                                    </TableCell>
                                                    <TableCell padding="none" style={sensorIdColumnStyle}>
                                                        {
                                                            React.Children.toArray(
                                                                sensorValues.slice(0, 2).map((sensorId, index) => {
                                                                    let project = this.getSensorProject(flight, sensorId);
                                                                    return (
                                                                        <NicerTooltip placement="right"
                                                                            content={
                                                                                <span>
                                                                                    <strong>Project Name:</strong>&nbsp;{project["ProjectName"]}<br />
                                                                                    <strong>Project Manager:</strong>&nbsp;{
                                                                                        this.getSensorProject(flight, sensorId)["ProjectManager"]
                                                                                    }<br />
                                                                                    <strong>Project Status:</strong>&nbsp;{project["ProjectStatus"]}
                                                                                </span>
                                                                            }>
                                                                            {project["ProjectID"]}
                                                                        </NicerTooltip>);
                                                                })
                                                            )
                                                        }
                                                        {
                                                            (sensorValues.length > 2)
                                                                && <NicerTooltip placement="right"
                                                                    content={
                                                                        React.Children.toArray(
                                                                            sensorValues.map((sensorId, index) => {
                                                                                console.log(`index: ${index}`)
                                                                                let project = this.getSensorProject(flight, sensorId);
                                                                                return (
                                                                                    <span>
                                                                                        <strong>Project ID:</strong>&nbsp;{project["ProjectID"]}<br />
                                                                                        <strong>Project Name:</strong>&nbsp;{project["ProjectName"]}<br />
                                                                                        <strong>Project Status:</strong>&nbsp;{project["ProjectStatus"]}<br />
                                                                                        {   index != (sensorValues.length-1)
                                                                                                && <br />
                                                                                        }
                                                                                    </span>
                                                                                ) 
                                                                            })
                                                                        )
                                                                    }>
                                                                    <span><strong>...</strong><br /></span>
                                                                </NicerTooltip>
                                                        }
                                                    </TableCell>
                                                    <TableCell padding="none" style={dateColumnStyle}>
                                                        <NicerTooltip placement="right"
                                                            content={
                                                                <span>
                                                                    <strong>Wheels Up:</strong>&nbsp;{flight.WheelsUp.split("T")[1]}<br />
                                                                    <strong>Wheels Down:</strong>&nbsp;{flight.WheelsDown.split("T")[1]}
                                                                </span>
                                                            }>
                                                            {flight.Date.split("T")[0]}
                                                        </NicerTooltip>
                                                    </TableCell>

                                                    {/* <TableCell padding="none" style={dateColumnStyle}>{flight.WheelsUp.split("T")[1]}<br />{flight.WheelsDown.split("T")[1]}</TableCell>
                                                    <TableCell padding="none" style={sensorIdColumnStyle}>
                                                        {
                                                            React.Children.toArray(
                                                                sensorValues.slice(0, 2).map((sensorId, index) => {
                                                                    let projectManager = this.getSensorProject(flight, sensorId)["ProjectManager"]
                                                                    return (
                                                                        (sensorValues.slice(0, 2).length ==2) 
                                                                            ? <NicerTooltip placement="right"
                                                                                content={
                                                                                    <span><strong>{projectManager}</strong><br /></span>
                                                                                }
                                                                            >
                                                                                <div style={{maxHeight: '1rem', maxWidth: 75, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} >
                                                                                    <span>{projectManager}<br/></span>
                                                                                </div>
                                                                            </NicerTooltip>
                                                                            : <span>{projectManager}<br/></span>
                                                                    )
                                                                })
                                                            )
                                                        }
                                                        {
                                                            (sensorValues.length > 2)
                                                                && <NicerTooltip placement="right"
                                                                    content={
                                                                        React.Children.toArray(
                                                                            sensorValues.map((sensorId, index) => { 
                                                                                return (
                                                                                    <span>{this.getSensorProject(flight, sensorId)["ProjectManager"]}<br/></span>
                                                                                ) 
                                                                            })
                                                                        )
                                                                    }>
                                                                    <span><strong>...</strong><br /></span>
                                                                </NicerTooltip>
                                                        }
                                                    </TableCell> */}
                                                    <TableCell padding="none" style={sensorNameColumnStyle}>
                                                        {
                                                            sensors.map(sensor => {
                                                                return (
                                                                    <span>
                                                                        {sensor.Sensor}<br/>
                                                                        {
                                                                            sensor.SecondarySensor &&
                                                                            sensor.SecondarySensor != "NA" &&
                                                                            <span style={{ fontSize: 9, color: "#757575" }}>{sensor.SecondarySensor}</span>
                                                                        }
                                                                    </span>
                                                                );
                                                            })
                                                        }
                                                    </TableCell>
                                                    <TableCell padding="none" style={uploadStatusColumnStyle}><TableSensorTypeIcon sensorType={this.getSensorValues(flight, "Type")} /></TableCell>
                                                    <TableCell padding="none" style={aircraftColumnStyle}>{flight.Aircraft}</TableCell>
                                                    <TableCell padding="none" style={uploadStatusColumnStyle}><TableAircraftTypeIcon type={flight.AircraftType} /></TableCell>
                                                    {/*<TableCell padding="none" style={dispatchColumnStyle}>
                                                        {
                                                            isEnableCollapse
                                                                ? <>
                                                                    <NicerTooltip placement="right"
                                                                        content={
                                                                            <span>
                                                                                {
                                                                                    isCollapseOpen 
                                                                                        ? <span style={{ fontStyle: 'italic' }}>Collapse to hide details</span>
                                                                                        : <span style={{ fontStyle: 'italic' }}>Expand to view more details</span>
                                                                                }
                                                                            </span>
                                                                        }
                                                                    >
                                                                        <IconButton 
                                                                            onClick={() => this.handleCollapsetoggle(flight.FlightID)}
                                                                            style={{ width: 40, height: 40 }}
                                                                        >
                                                                            {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
                                                                        </IconButton>
                                                                    </NicerTooltip>
                                                                    <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                                                                        <FlightDataDispatchTableIcon
                                                                            // type="LiDAR"
                                                                            status={this.getSensorAndUploadValues(flight, "DataDispatchMethodLiDAR")}
                                                                            archiveS3Path={this.getArchiveS3Path(flight, 'LiDAR')}
                                                                            documentMajorVersion={flight['DocumentMajorVersion']}
                                                                            explorerOnClick={this.props.explorerOnClickCallBack}
                                                                        />
                                                                    </Collapse>
                                                                </>
                                                                : <FlightDataDispatchTableIcon
                                                                    // type="LiDAR"
                                                                    status={this.getSensorAndUploadValues(flight, "DataDispatchMethodLiDAR")}
                                                                    archiveS3Path={this.getArchiveS3Path(flight, 'LiDAR')}
                                                                    documentMajorVersion={flight['DocumentMajorVersion']}
                                                                    explorerOnClick={this.props.explorerOnClickCallBack}
                                                                />
                                                        }
                                                    </TableCell>
                                                    <TableCell padding="none" style={dispatchColumnStyle}>
                                                        <FlightDataDispatchTableIcon
                                                            // type="Imagery"
                                                            status={this.getSensorAndUploadValues(flight, "DataDispatchMethodImagery")}
                                                            archiveS3Path={this.getArchiveS3Path(flight, 'Imagery')}
                                                            documentMajorVersion={flight['DocumentMajorVersion']}
                                                            explorerOnClick={this.props.explorerOnClickCallBack}
                                                        />
                                                    </TableCell> */}

                                                    <TableCell padding="none" style={fieldUploadColumnStyle}>
                                                        { 
                                                            flight.Sensors[0].Sensor.toLowerCase().includes("terrain mapper") &&
                                                            <TerrainMapperFieldUploads
                                                                flight={flight}
                                                                explorerOnClick={this.props.explorerOnClickCallBack}
                                                                getArchiveS3Path={this.getArchiveS3Path}/>
                                                        }
                                                        { 
                                                            !flight.Sensors[0].Sensor.toLowerCase().includes("terrain mapper") &&
                                                            <ul style={{listStyleType:"none", display:"flex", padding:0, margin:0, gap:"10px", justifyContent:"center"}}>
                                                                <li>
                                                                    <span style={{fontWeight:"bolder", fontSize:10}}>LiDAR</span>
                                                                    <FlightDataDispatchTableIcon
                                                                            status={this.getSensorAndUploadValues(flight, "DataDispatchMethodLiDAR")}
                                                                            archiveS3Path={this.getArchiveS3Path(flight, 'LiDAR')}
                                                                            documentMajorVersion={flight['DocumentMajorVersion']}
                                                                            explorerOnClick={this.props.explorerOnClickCallBack}
                                                                        />
                                                                </li>
                                                                <li>
                                                                    <span style={{fontWeight:"bolder", fontSize:10}}>Imagery</span>
                                                                    <FlightDataDispatchTableIcon
                                                                        status={this.getSensorAndUploadValues(flight, "DataDispatchMethodImagery")}
                                                                        archiveS3Path={this.getArchiveS3Path(flight, 'Imagery')}
                                                                        documentMajorVersion={flight['DocumentMajorVersion']}
                                                                        explorerOnClick={this.props.explorerOnClickCallBack}
                                                                    />
                                                                </li>
                                                            </ul>
                                                        }

                                                    </TableCell>

                                                    <TableCell padding="none" style={officeUploadColumnStyle}>
                                                        { flight.Sensors[0].Sensor.toLowerCase().includes("terrain mapper") &&
                                                            <TerrainMapperOfficeUploads flight={flight}/>
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10]}
                                                // colSpan={7}
                                                count={queryParams.total}
                                                rowsPerPage={queryParams.pageSize}
                                                page={queryParams.page - 1}
                                                onChangePage={this.changePage}
                                            // onChangeRowsPerPage={this.changePageSize}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                            <div className='pl-3'>
                                <NicerTooltip placement="top" content="Search">
                                    <IconButton 
                                        color="primary" 
                                        style={{ marginBottom: 10 }} 
                                        onClick={this.props.filtersBtnOnClick}
                                    >
                                        <Search />
                                    </IconButton>
                                </NicerTooltip>
                            </div>
                        </Grid>
                }
            </>
        )
    }
}

//add style and connect to redux
FlightTable = connect(state => ({
    queryParams: state.flightsQueryParams
}), dispatch => ({
    setQueryParams: payload => {
        dispatch(flightsQueryParams(payload))
        dispatch(flightsFetchList())
    },
    clearAllFilters: () => { dispatch(clearAllFilters(true)) },
}))(FlightTable)

// export default FlightSummary;
const mapStateToProps = (state) => {
    return {
        token: state.token,
        flightsListLoading: state.flightsListLoading,
        flights: state.flights,
        sensorEquipmentListLoading: state.sensorEquipmentListLoading,
        sensorEquipment: state.sensorEquipment ? state.sensorEquipment.map(item => { return { "label": item.Name, "value": item.Name } }) : [],
        aircraftListLoading: state.aircraftListLoading,
        aircraft: state.aircraft ? state.aircraft.map(item => { return { "label": item.Name, "value": item.Name } }) : [],
        projectManagers: state.uniqueValuesFetchSuccess && state.uniqueValuesFetchSuccess["project.ProjectManager"] ?
            state.uniqueValuesFetchSuccess["project.ProjectManager"].map(item => { return { "label": item, "value": item } }) : [],
        projectManagersIsLoading: state.uniqueValuesFetchLoading,
        projectManagersHasError: state.uniqueValuesFetchHasError,
        explorerStore: state.objectExplorer
    };
};

// //this is needed to match thre returned result to properties
const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (token) => { dispatch(flightsFetchList(token)) },
        fetchSensorsAndAircraft: (token) => {
            dispatch(aircraftFetchList(token));
            dispatch(sensorEquipmentFetchList(token))
        },
        fetchUniqueValues: (token, collection, field) => {
            dispatch(uniqueValuesFetch(token, collection, field))
        },
        handleValidateArchive: (bucket, prefix) => { dispatch(handleValidateArchive(bucket, prefix)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightSummary);